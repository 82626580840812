<div class="edit-dt-root">
  <div class="edit-dt-header">
    <div class="dt-header-info">
      <div class="dt-info">
        <div class="name">
          {{ dtHeaderInfo.name ? dtHeaderInfo.name : ("COMMON.NO_NAME" | translate) }}
        </div>
        <div class="row">
          <div class="slrn col-7">
            {{ dtHeaderInfo.slrn ? dtHeaderInfo.slrn : ("COMMON.NO_SLRN" | translate) }}
          </div>
          <div></div>
          <div class="col-2 dt-status complete-dt"
            *ngIf="disableUpdateStatusButton() && dt.dataQualityStatus!.toString() =='Approved'"> {{'COMMON.ASSET_STATUS.COMPLETE' | translate}} </div>
          <div class="col-2 dt-status rejected-dt"
            *ngIf="disableUpdateStatusButton() && dt.dataQualityStatus!.toString() =='Rejected'"> {{'COMMON.ASSET_STATUS.REJECTED' | translate}} </div>
          <div class="col-2 dt-status" *ngIf="!disableUpdateStatusButton() && dt.status"
               [ngClass]="{'pending-dt' : dt.status == 'Pending',
                           'assigned-dt' : dt.status == 'Assigned' || dt.status == 'Retagged'}"> 
            {{'COMMON.ASSET_STATUS.' + dt.status | uppercase | translate}}
          </div>
          <div class="col-2 dt-status pending-dt"
            *ngIf="!disableUpdateStatusButton() && !dt.status">--------</div>
          </div>
      </div>
      <div class="dt-address">
        <div class="name">{{'TRANSFORMERS.EDIT.SLRN' | translate}}</div>
        <div *ngIf="dt.dtStatus" class="dt-status" [ngClass]="{
            'active-dt': dt.dtStatus.toString() == 'Active',
            'inactive-dt': dt.dtStatus.toString() == 'Inactive',
            'suspended-dt': dt.dtStatus.toString() == 'Suspended',
            'decomissioned-dt': dt.dtStatus.toString() == 'Decommissioned'
          }">
          {{ 'TRANSFORMERS.DT_STATUS_OPTIONS.' + dt.dtStatus | uppercase | translate }}
        </div>
        <div>{{ dtHeaderInfo?.locationAddress | hasValue }}</div>
      </div>
    </div>
    <div class="action-buttons">
      <button class="btn btn-header" data-bs-toggle="modal" (click)="getMobileUsers(); setModalType(AssetAssignType.Retagged);"
        data-bs-target="#assignToFieldAgent" *ngIf="permissionsService.isTransformersRetagAllowed">
        {{'COMMON.RETAG' | translate}}
      </button>
      <div class="recall-div" *ngIf="
          permissionsService.isTransformersRecallAssignedAllowed ||
          permissionsService.isTransformersRecallValidatedAllowed
        ">
        <button class="btn btn-header recall-button" style="color: black" (click)="showRecallMenu = !showRecallMenu">
          <span class="recall-span">{{'COMMON.RECALL' | translate}}</span>
        </button>
        <div *ngIf="showRecallMenu" class="recall-options">
          <div class="recall-type" [ngClass]="[
              !recallAssignedAllowed
                ? 'recall-type-disabled'
                : 'recall-type-enabled'
            ]" (click)="recallAssignedAllowed ? recall() : null">
            <span>{{'COMMON.ASSIGNED_TASK' | translate}}</span>
          </div>
          <div class="recall-type" [ngClass]="[
              !recallValidatedAllowed
                ? 'recall-type-disabled'
                : 'recall-type-enabled'
            ]" (click)="recallValidatedAllowed ? recall() : null">
            <span>{{'COMMON.VALIDATED_TASK' | translate}}</span>
          </div>
        </div>
      </div>

      <button class="btn btn-header" data-bs-toggle="modal" data-bs-target="#updateStatus"
        [disabled]="disableUpdateStatusButton()" *ngIf="permissionsService.isTransformersApproveRejectAllowed">
        {{'COMMON.UPDATE_STATUS' | translate}}
      </button>
      <button class="btn btn-header" data-bs-toggle="modal" data-bs-target="#auditReview"
        [disabled]="disableAuditReviewButton()" *ngIf="permissionsService.isTransformersAuditReviewAllowed">
        {{'COMMON.FIELD_AUDIT' | translate}}
      </button>
      <button class="btn btn-header" data-bs-toggle="modal" (click)="getMobileUsers();  setModalType(AssetAssignType.Assigned);"
        data-bs-target="#assignToFieldAgent" *ngIf="permissionsService.isTransformersAssignAllowed">
        {{'COMMON.ASSIGN_AGENT' | translate}}
      </button>
      <button class="btn btn-header" *ngIf="!editEnabled && permissionsService.isTransformersCreateEditAllowed"
        (click)="edit()">
        {{'COMMON.EDIT' | translate}}
      </button>
      <button class="btn btn-primary" *ngIf="editEnabled" (click)="validateInput($event)">
        {{'COMMON.SAVE' | translate}}
      </button>
      <button class="btn btn-primary" #openUpdateDescriptionModal data-bs-toggle="modal" data-bs-target="#history"
        hidden></button>
    </div>
  </div>

  <div class="edit-dt-body">
    <div class="main-col">
      <div class="card container">
        <div class="row">
          <div class="col-5">
            <p class="card-title">{{'TRANSFORMERS.EDIT.INFORMATION' | translate}}</p>
          </div>
          <div class="col-7">
            <div class="line-2"></div>
          </div>
        </div>
  
        <div class="row">
          <div class="col-5">{{'TRANSFORMERS.EDIT.KVA_RATING' | translate}}</div>
          <div class="col-7">{{ dt.kvaRating | hasValue }}</div>
        </div>
        <div class="row">
          <div class="col-5">{{'TRANSFORMERS.DT_TYPE' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">{{ dt.type | hasValue }}</div>
          <div *ngIf="editEnabled" class="col-7">
            <select
              name="selectStatus"
              (change)="selectType($event)"
              required
              class="form-select"
            >
              <option
                value="Private"
                [selected]="dt.type.toString() === 'Private'"
              >
                {{'COMMON.PRIVATE' | translate}}
              </option>
              <option value="Public" [selected]="dt.type.toString() === 'Public'">
                {{'COMMON.PUBLIC' | translate}}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-5">{{'TRANSFORMERS.EDIT.MOUNT_POS' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">
            {{ dt.mountPosition | hasValue }}
          </div>
          <div *ngIf="editEnabled" class="col-7">
            <select
              name="selectStatus"
              (change)="selectMountPosition($event)"
              required
              class="form-select"
            >
              <option
                value="Ground"
                [selected]="dt.mountPosition.toString() === 'Ground'"
              >
                {{'COMMON.TRANSFORMER.TYPE.GROUND' | translate}}
              </option>
              <option
                value="Pole"
                [selected]="dt.mountPosition.toString() === 'Pole'"
              >
                {{'COMMON.TRANSFORMER.TYPE.POLE' | translate}}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-5">{{'TRANSFORMERS.EDIT.TAP_POSITIONING' | translate}}</div>
          <div class="col-7">{{ dt.tapPositioning | hasValue }}</div>
        </div>
        <div class="row">
          <div class="col-5">{{'TRANSFORMERS.EDIT.IMPENDANCE_PERC' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">
            {{ dt.impedancePercentage | hasValue
            }}{{ dt.impedancePercentage !== undefined ? "%" : "" }}
          </div>
          <input *ngIf="editEnabled" type="number" class="col-7" [(ngModel)]="dt.impedancePercentage"/>

        </div>
        <div class="row">
          <div class="col-5">{{'TRANSFORMERS.EDIT.MANUFACTURER_NAME' | translate}}</div>
          <div class="col-7" *ngIf="!editEnabled" >{{ dt.manufacturerName | hasValue }}</div>
          <input *ngIf="editEnabled" class="col-7" [(ngModel)]="dt.manufacturerName"/>
        </div>
        <div class="row">
          <div class="col-5">{{'TRANSFORMERS.EDIT.SERIAL_NUM' | translate}}</div>
          <div class="col-7" *ngIf="!editEnabled">{{ dt.serialNumber | hasValue }}</div>
          <input *ngIf="editEnabled" class="col-7" [(ngModel)]="dt.serialNumber"/>
        </div>
        <div class="row">
          <div class="col-5">{{'TRANSFORMERS.EDIT.FENCING_STATUS.TITLE' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">
            {{ formatFencingStatus(dt.fencingStatus) | hasValue }}
          </div>
          <div *ngIf="editEnabled" class="col-7">
            <select
              name="selectStatus"
              (change)="selectFencingStatus($event)"
              required
              class="form-select"
            >
              <option
                value="NoFence"
                [selected]="dt.fencingStatus.toString() === 'NoFence'"
              >
              {{'TRANSFORMERS.EDIT.FENCING_STATUS.NO_FENCE' | translate}}
              </option>
              <option
                value="Fenced"
                [selected]="dt.fencingStatus.toString() === 'Fenced'"
              >
              {{'TRANSFORMERS.EDIT.FENCING_STATUS.FENCED' | translate}}
              </option>
              <option
                value="Compact"
                [selected]="dt.fencingStatus.toString() === 'Compact'"
              >
              {{'TRANSFORMERS.EDIT.FENCING_STATUS.COMPACT' | translate}}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-5">{{'TRANSFORMERS.DT_STATUS' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">
            {{ dt.dtStatus | hasValue }}
          </div>
          <div *ngIf="editEnabled" class="col-7">
            <select
              name="selectStatus"
              (change)="selectDtStatus($event)"
              required
              class="form-select"
            >
              <option
                value="Active"
                [selected]="dt.dtStatus.toString() === 'Active'"
              >
              {{'TRANSFORMERS.DT_STATUS_OPTIONS.ACTIVE' | translate}}
              </option>
              <option
                value="Inactive"
                [selected]="dt.dtStatus.toString() === 'Inactive'"
              >
              {{'TRANSFORMERS.DT_STATUS_OPTIONS.INACTIVE' | translate}}
              </option>
              <option
                value="Suspended"
                [selected]="dt.dtStatus.toString() === 'Suspended'"
              >
              {{'TRANSFORMERS.DT_STATUS_OPTIONS.SUSPENDED' | translate}}
              </option>
              <option
                value="Decommissioned"
                [selected]="dt.dtStatus.toString() === 'Decommissioned'"
              >
              {{'TRANSFORMERS.DT_STATUS_OPTIONS.DECOMMISSIONED' | translate}}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-5">{{'TRANSFORMERS.EDIT.MANUFACTURE_DATE' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">
            {{ dt.dateOfManufacturer| date:'dd/MM/yyyy HH:mm':'{{ timeZone }}' | hasValue }}
          </div>
          <div *ngIf="editEnabled" class="col-7">
            <input
              type="date"
              id="dateManufacturer"
              name="dateManufacturer"
              class="btn datepicker-icon"
              [(ngModel)]="dateManufacturerString"
              (change)="setDateOfManufacturer()"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-5">{{'TRANSFORMERS.EDIT.COMMISSIONING_DATE' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">
            {{ dt.dateOfCommissioning| date:'dd/MM/yyyy HH:mm':'{{ timeZone }}' | hasValue }}
          </div>
          <div *ngIf="editEnabled" class="col-7">
            <input
              type="date"
              id="dateComission"
              name="dateComission"
              class="btn datepicker-icon"
              [(ngModel)]="dateComissionString"
              (change)="setDateOfComission()"
            />
          </div>
        </div>
  
        <div class="row">
          <div class="col-5">{{'TRANSFORMERS.EDIT.LOCATION_ADDRESS' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">
            {{ dt.locationAddress | hasValue }}
          </div>
          <input
            *ngIf="editEnabled"
            class="col-7"
            [(ngModel)]="dt.locationAddress"
          />
        </div>
        <div class="row">
          <div class="col-5">{{'TRANSFORMERS.EDIT.LOCATION_LANDMARK' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">
            {{ dt.locationLandmark | hasValue }}
          </div>
          <input
            *ngIf="editEnabled"
            class="col-7"
            [(ngModel)]="dt.locationLandmark"
          />
        </div>
        <ng-container *ngIf="isGhanaPostFieldsVisible">
          <div class="row">
              <div class="col-5">{{'COMMON.GHANA_POST_GPS' | translate}}</div>
              <div class="col-7">{{ dt.postCode | hasValue }}</div>
            </div>
            <div class="row" *ngIf="dt.postCodeException">
              <div class="col-5">{{'COMMON.GHANA_POST_GPS_EXC' | translate}}</div>
              <div class="col-7">{{ dt.postCodeException | hasValue }}</div>
            </div>
         </ng-container>
  
        <div class="row" *ngIf="dt.longitude != null && dt.longitude != null">
          <div class="locationText col-5">{{'COMMON.MBC_DETAILS.GEO_LOCATION' | translate}}</div>
          <div class="locationText col-6 d-flex justify-content-end">
            {{ dt.latitude }} , {{ dt.longitude }}
          </div>
          <div class="location col-1" (click)="isMapVisible = !isMapVisible">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 500 500"
              data-bs-toggle="modal"
              data-bs-target="#showMap"
            >
              <path
                d="M256 0c17.7 0 32 14.3 32 32V66.7C368.4 80.1 431.9 143.6 445.3 224H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H445.3C431.9 368.4 368.4 431.9 288 445.3V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V445.3C143.6 431.9 80.1 368.4 66.7 288H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H66.7C80.1 143.6 143.6 80.1 224 66.7V32c0-17.7 14.3-32 32-32zM128 256c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128s-128 57.3-128 128zm128 80c-44.2 0-80-35.8-80-80s35.8-80 80-80s80 35.8 80 80s-35.8 80-80 80z"
                fill="#026962"
              />
            </svg>
          </div>
        </div>
        <div class="row" *ngIf="dt.longitude == null || dt.longitude == null">
          <div class="locationText col-5">{{'COMMON.MBC_DETAILS.GEO_LOCATION' | translate}}</div>
          <div class="locationText col-7 d-flex justify-content-end">
            {{ null | hasValue }}
          </div>
        </div>
  
        <div class="row">
          <div class="col-5">{{'COMMON.USER.TYPE.FIRST_CAPTURED' | translate}}</div>
          <div class="col-7">{{ dt.firstCapturedUser | hasValue }}</div>
        </div>
  
        <div class="row">
          <div class="col-5">{{'COMMON.USER.TYPE.LAST_CAPTURED' | translate}}</div>
          <div class="col-7">{{ dt.lastUpdatedUser | hasValue }}</div>
        </div>
        <div class="row">
          <div class="col-5">{{'COMMON.CREATION_DATE' | translate}}</div>
          <div class="col-7">
            {{ dt.creationDate| date:'dd/MM/yyyy HH:mm':'{{ timeZone }}' | hasValue }}
          </div>
        </div>
        <div class="row">
          <div class="col-5">{{'COMMON.MBC_DETAILS.UPDATED_DATE' | translate}}</div>
          <div class="col-7">
            {{ dt.updatedDate| date:'dd/MM/yyyy HH:mm':'{{ timeZone }}' | hasValue }}
          </div>
        </div>
        <div class="row">
          <div class="col-5">{{'COMMON.USER.TYPE.REASSIGNED' | translate}}</div>
          <div class="col-7">{{ dt.reassignedBy | hasValue }}</div>
        </div>
        <div class="row">
          <div class="col-5">{{'COMMON.MBC_DETAILS.ASSIGNED' | translate}}</div>
          <div class="col-7">{{ dt.assignedUser | hasValue }}</div>
        </div>
        <div class="row" *ngIf="permissionsService.isValidationStatusAllowed">
          <div class="col-5">{{'COMMON.USER.TYPE.VALIDATED' | translate}}</div>
          <div class="col-7">{{ dt.validatedBy | hasValue }}</div>
        </div>
        <div class="row" *ngIf="permissionsService.isValidationStatusAllowed">
          <div class="col-5">{{'COMMON.DATE_TYPE.VALIDATED' | translate}}</div>
          <div class="col-7">
            {{ dt.validatedDate| date:'dd/MM/yyyy HH:mm':'{{ timeZone }}' | hasValue }}
          </div>
        </div>
        <div class="row"  *ngIf="permissionsService.isAuditStatusAllowed">
          <div class="col-5">{{'COMMON.MBC_DETAILS.AUDITED_STATUS' | translate}}</div>
          <div class="col-7">{{ dt.auditedStatus | hasValue }}</div>
        </div>
        <div class="row"  *ngIf="permissionsService.isAuditStatusAllowed">
          <div class="col-5">{{'COMMON.USER.TYPE.AUDITED' | translate}}</div>
          <div class="col-7">{{ dt.auditedBy | hasValue }}</div>
        </div>
        <div class="row"  *ngIf="permissionsService.isAuditStatusAllowed">
          <div class="col-5">{{'COMMON.MBC_DETAILS.AUDITED_DATE' | translate}}</div>
          <div class="col-7">
            {{ dt.auditedDate| date:'dd/MM/yyyy HH:mm':'{{ timeZone }}' | hasValue }}
          </div>
        </div>
        <div class="row" *ngIf="permissionsService.isValidationStatusAllowed">
          <div class="col-5">{{'COMMON.VALIDATION_STATUS' | translate}}</div>
          <div class="col-7" *ngIf="disableUpdateStatusButton() && dt.dataQualityStatus!.toString() =='Approved'"> Complete </div>
          <div class="col-7" *ngIf="disableUpdateStatusButton() && dt.dataQualityStatus!.toString() =='Rejected'"> Rejected </div>
          <div class="col-7" *ngIf="!disableUpdateStatusButton() && dt.status"> 
            {{ dt.status }} 
          </div>
          <div class="col-7" *ngIf="!disableUpdateStatusButton() && !dt.status">--------</div>
        </div>
        <div class="row">
          <div class="col-5">{{'TRANSFORMERS.EDIT.SERIAL_NUM' | translate}}</div>
          <div class="col-7">{{ dt.serialNumber | hasValue }}</div>
        </div>
        <div class="row">
          <div class="col-5">{{'COMMON.COMMENT' | translate}}</div>
          <div class="col-7">{{ dt.comment | hasValue }}</div>
        </div>
        <div class="row">
          <div class="col-5">
            <p class="card-title">{{'TRANSFORMERS.EDIT.METER_STATUS' | translate}}</p>
          </div>
          <div class="col-7">
            <div class="line-2"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-5">{{'TRANSFORMERS.EDIT.METER_INSTALLED' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">
            {{ metered ? ("COMMON.YES" | translate) : ("COMMON.NO" | translate) }}
          </div>
          <div *ngIf="editEnabled" class="col-7">
            <select class="form-control" [(ngModel)]="metered">
              <option [ngValue]="true" [selected]="metered === true">{{'COMMON.YES' | translate}}</option>
              <option [ngValue]="false" [selected]="metered != true">{{'COMMON.NO' | translate}}</option>
            </select>
          </div>
        </div>
        <!-- <div *ngIf="!metered" class="row">
          <div class="col-5">{{'TRANSFORMERS.EDIT.XLPE_CABLE_LENGTH' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">{{ dt.isCableLengthEnough ? ("COMMON.YES" | translate) :
            (dt.isCableLengthEnough == false ? ("COMMON.NO" | translate) : (dt.isCableLengthEnough | hasValue)) }}</div>
          <div *ngIf="editEnabled" class="col-7">
            <select class="form-control" [(ngModel)]="dt.isCableLengthEnough">
              <option [ngValue]="true" [selected]="dt.isCableLengthEnough === true">{{'COMMON.YES' | translate}}</option>
              <option [ngValue]="false" [selected]="dt.isCableLengthEnough != true">{{'COMMON.NO' | translate}}</option>
            </select>
          </div>
        </div> -->
        <div *ngIf="metered" class="row">
          <div class="col-5">{{'TRANSFORMERS.EDIT.CONDITION' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">
            {{ dt.meter?.condition | hasValue }}
          </div>
          <div *ngIf="editEnabled" class="col-7">
            <select class="form-control" [(ngModel)]="dt.meter!.condition">
              <option
                *ngFor="let option of meterConditionOptions"
                [ngValue]="option.value"
              >
                {{ option.name | translate }}
              </option>
            </select>
          </div>
        </div>
        <div *ngIf="metered" class="row">
          <div class="col-5">{{'COMMON.DT_METER_TYPE.SINGLE' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">
            {{ dt.meter?.physicalMeterType | hasValue }}
          </div>
          <div *ngIf="editEnabled" class="col-7">
            <select
              class="form-control"
              [(ngModel)]="dt.meter!.physicalMeterType"
            >
              <option
                *ngFor="let option of physicalMeterTypeOptions"
                [ngValue]="option.value"
              >
                {{ option.name | translate }}
              </option>
            </select>
          </div>
        </div>
        <div *ngIf="metered" class="row">
          <div class="col-5">{{'TRANSFORMERS.EDIT.COMMUNICATION_STATUS' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">
            {{
              formatCommunicationStatus(dt.meter?.communicationStatus) | hasValue
            }}
          </div>
          <div *ngIf="editEnabled " class="col-7">
            <select
              class="form-control"
              [(ngModel)]="dt.meter!.communicationStatus"
          
            >
              <option
                *ngFor="let option of communicationOptions"
                [ngValue]="option.value"
                
              >
                {{ option.name | translate }}
              </option>
            </select>
          </div>
        </div>
        <div *ngIf="metered" class="row">
          <div class="col-5">{{'TRANSFORMERS.EDIT.METER_BRAND' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">
            {{ dt.meter?.meterType?.name | hasValue }}
          </div>
          <div *ngIf="editEnabled" class="col-7">
            <select class="form-control" *ngIf="dt.meter!.meterType && dt.meter!.meterType.id">
              <option
                *ngFor="let option of meterTypeOptions"
                [ngValue]="option.id"
              >
                {{ option.name }}
              </option>
            </select>
          </div>
        </div>
        <div *ngIf="metered" class="row">
          <div class="col-5">{{'TRANSFORMERS.EDIT.METER_NUM' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">{{ dt.meter?.number | hasValue }}</div>
          <input
            *ngIf="editEnabled"
            class="col-7"
            [(ngModel)]="dt.meter!.number"
          />
        </div>
        <!-- <div *ngIf="metered" class="row">
          <div class="col-5">{{'TRANSFORMERS.EDIT.METER_REPOSITIONING' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">{{ dt.proposedLocation ? ("COMMON.YES" | translate) : ("COMMON.NO" | translate) }}</div>
          <div *ngIf="editEnabled" class="col-7">
            <select class="form-control" [(ngModel)]="doesMeterNeedRepositioning" (change)="onRepositioningChange(doesMeterNeedRepositioning)">
              <option [ngValue]="true" [selected]="dt.proposedLocation != undefined">{{'COMMON.YES' | translate}}</option>
              <option [ngValue]="false" [selected]="dt.proposedLocation == undefined">{{'COMMON.NO' | translate}}</option>
            </select>
          </div>
        </div>
        <div *ngIf="metered && doesMeterNeedRepositioning" class="row">
          <div class="col-5">{{'TRANSFORMERS.EDIT.PROPOSED_LOCATION' | translate}}</div>
          <div *ngIf="!(editEnabled && doesMeterNeedRepositioning)" class="col-7">{{ dt.proposedLocation | hasValue }}</div>
          <div *ngIf="editEnabled && doesMeterNeedRepositioning" class="col-7">
            <select class="form-control" [(ngModel)]="dt.proposedLocation">
              <option *ngFor="let o of proposedLocationOptions" [ngValue]="o.value"
                [selected]="dt.proposedLocation?.toLowerCase() == o.value.toLowerCase()">{{o.name | translate}}</option>
            </select>
          </div>
        </div>
        <div *ngIf="metered" class="row">
          <div class="col-5">{{'TRANSFORMERS.EDIT.METERING_CABLE_VISIBLE' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">{{ dt.isCableVisible ? ("COMMON.YES" | translate) : (dt.isCableVisible
            == false ? ("COMMON.NO" | translate) : (dt.isCableVisible | hasValue)) }}</div>
          <div *ngIf="editEnabled" class="col-7">
            <select class="form-control" [(ngModel)]="dt.isCableVisible">
              <option [ngValue]="true" [selected]="dt.isCableVisible === true">{{'COMMON.YES' | translate}}</option>
              <option [ngValue]="false" [selected]="dt.isCableVisible != true">{{'COMMON.NO' | translate}}</option>
            </select>
          </div>
        </div> -->
      </div>
      <div *ngIf="dt.dtHistories">
        <app-history-tracker [histories]="dt.dtHistories"></app-history-tracker>
      </div>
    <div class="history-card container"  *ngIf="isDataQuality && dt.dataValidationReview != null && dt.dataValidationReview.length > 0">
      <h4 style="text-align: center">{{'COMMON.DATA_VALID_REVIEW' | translate}}</h4>
      <div class="row" *ngFor="let dq of dt.dataValidationReview">
        <div class="col-5">{{ dq.key }}</div>
        <div *ngIf="dq.key != 'Reason for rejection'" class="col-7">
          {{ dq.value| hasValue }}
        </div>
        <div *ngIf="dq.key == 'Reason for rejection'" class="col-7">
          <a data-bs-toggle="modal" data-bs-target="#rejectionReason">{{'COMMON.VIEW' | translate | lowercase}}</a>
        </div>
      </div>
    </div>
  </div>

    <div class="card container main-col">
      <div class="row">
        <div class="col-5">
          <p class="card-title">{{'TRANSFORMERS.EDIT.NETWORK_INFO' | translate}}</p>
        </div>
        <div class="col-7">
          <div class="line-2"></div>
        </div>
      </div>
      <div class="row" *ngIf="dt.feeder11 != null">
        <div class="col-4">{{'TRANSFORMERS.EDIT.NETWORK_NOMENCLATURE' | translate}}</div>
        <div class="col-8">
          {{ dtsConnectedTo11KVFeeder | hasValue }}
        </div>
      </div>

      <div class="row" *ngIf="dt.feeder11 == null">
        <div class="col-4">{{'TRANSFORMERS.EDIT.NETWORK_NOMENCLATURE' | translate}}</div>
        <div class="col-8">
          {{ dtsConnectedTo33KVFeeder | hasValue }}
        </div>
      </div>
      <div class="row" *ngIf="dt.feeder33 != null">
        <div class="col-4">{{'COMMON.TS.SINGLE' | translate}}</div>
        <div class="col-8">
          {{ dt.feeder33 ? dt.feeder33.transmissionStation : ("" | hasValue) }}
        </div>
      </div>
      <div class="row" *ngIf="dt.feeder33 != null">
        <div class="col-5">{{'TRANSFORMERS.EDIT.33KV_FEEDER_NAME' | translate}}</div>
        <div class="col-7">{{ dt.feeder33?.name | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.FEEDER_VOLTAGE' | translate}}</div>
        <div class="col-7">
          {{ dt.feeder11 ? "11KV" : ("33KV" | hasValue) }}
        </div>
      </div>
      <div class="row" *ngIf="dt.feeder11 != null">
        <div class="col-5">{{'COMMON.SUBSTATION' | translate}}</div>
        <div class="col-7">
          {{ dt.feeder11 ? dt.feeder11.substation : ("" | hasValue) }}
        </div>
      </div>
      <div class="row" *ngIf="dt.feeder11 != null">
        <div class="col-5">{{'TRANSFORMERS.EDIT.11KV_FEEDER_NAME' | translate}}</div>
        <div class="col-7">{{ dt.feeder11?.name | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.DT_NAME' | translate}}</div>
        <div class="col-7">{{ dt.name | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.DT_NUMBER' | translate}}</div>
        <div class="col-7">{{ dt.number | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.BU.SINGLE' | translate}}</div>
        <div class="col-7">{{ dt.area.regionName | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.UT.SINGLE' | translate}}</div>
        <div class="col-7">{{ dt.area.name | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">{{'TRANSFORMERS.EDIT.SERVICE_CENTER' | translate}}</div>
        <div class="col-7">{{ dt.serviceCentre | hasValue }}</div>
      </div>

      <div class="row">
        <div class="col-5">
          <p class="card-title">{{'TRANSFORMERS.EDIT.FEEDER_POINT_INFO' | translate}}</p>
        </div>
        <div class="col-7">
          <div class="line-2"></div>
        </div>
      </div>
      <div class="row">
        <div class="col-4">{{'TRANSFORMERS.EDIT.FEEDER_POINT_CONDITION' | translate}}</div>
        <div class="col-8">{{ dt.feederPointCondition }}</div>
      </div>
      <div class="row">
        <div class="col-4">{{'TRANSFORMERS.EDIT.FEEDER_PILLAR' | translate}}</div>
        <div class="col-8">{{ dt.feederPillar }}</div>
      </div>
      <div class="row">
        <div class="col-4">{{'TRANSFORMERS.EDIT.FEEDER_PILLAR_COND' | translate}}</div>
        <div class="col-8">
          {{ formatFeederCondition(dt.feederPillarCondition) | hasValue }}
        </div>
      </div>
      <div class="row">
        <div class="col-4">{{'TRANSFORMERS.EDIT.SUB_FEEDER_PILLAR' | translate}}</div>
        <div class="col-8">{{ dt.subFeederPillar }}</div>
      </div>
      <div class="row">
        <div class="col-4">{{'TRANSFORMERS.EDIT.SUB_FEEDER_PILLAR_COND' | translate}}</div>
        <div class="col-8">
          {{ formatFeederCondition(dt.subFeederPillarCondition) | hasValue }}
        </div>
      </div>
      <div class="row">
        <div class="col-4">{{'TRANSFORMERS.EDIT.UPRISER_CABLE_NUM' | translate}}</div>
        <div class="col-8">{{ dt.numberOfUpriserCable | number | hasValue }}</div>
      </div>
      <div class="row" *ngFor="let upriser of dt.upriserCableCondition; let i = index">
        <div class="col-4">{{'TRANSFORMERS.EDIT.UPRISER_CABLE' | translate}} {{ i + 1 }}</div>
        <div class="col-8">
          {{ formatFeederCondition(upriser) }}
        </div>
      </div>
      <div class="row">
        <div class="col-4">{{'TRANSFORMERS.EDIT.CONDUCTOR_SIZE' | translate}}</div>
        <div class="col-8">{{ dt.conductorSize | hasValue }}</div>
      </div>
      <!-- <div class="row">
        <div class="col-4">{{'TRANSFORMERS.EDIT.XLPE_CABLE_CONDITION' | translate}}</div>
        <div *ngIf="!editEnabled" class="col-8">{{ dt.xlpeCableCondition | hasValue }}</div>
        <div *ngIf="editEnabled" class="col-8">
          <select class="form-control" [(ngModel)]="dt.xlpeCableCondition" style="margin-left: auto;">
            <option *ngFor="let o of xlpeCableConditionOptions" [value]="o.value" [selected]="dt.xlpeCableCondition == o.value">{{o.name | translate}}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-4">{{'TRANSFORMERS.EDIT.XLPE_CABLE_SIZE' | translate}}</div>
        <div *ngIf="!editEnabled" class="col-8">{{ dt.xlpeCableSize | hasValue }}</div>
        <div *ngIf="editEnabled" class="col-8">
          <select class="form-control" [(ngModel)]="dt.xlpeCableSize" style="margin-left: auto;">
            <option *ngFor="let o of xlpeCableSizeOptions" [value]="o.value" [selected]="dt.xlpeCableSize == o.value">{{o.viewValue}}</option>
          </select>
        </div>
      </div> -->
      <div class="row">
        <div class="col-5">
          <p class="card-title">{{'TRANSFORMERS.EDIT.CONNECTED_CBP' | translate}}</p>
        </div>
        <div class="col-7">
          <div class="line-2"></div>
        </div>
      </div>
      <div class="row conn-assets">
        <div class="col-4">{{'TRANSFORMERS.EDIT.CUSTOMER_POPULATION' | translate}}</div>
        <div class="col-7">{{ dt.customersCount| number }}</div>
        <img class="col-1" (click)="viewConnectedAssets(1, dt.id, dt.name)" title="View" src="assets/icons/angles-right.svg">
      </div>
      <div class="row conn-assets">
        <div class="col-4">{{'TRANSFORMERS.EDIT.CONNECTED_BUILDINGS_NUM' | translate}}</div>
        <div class="col-7">{{ dt.buildingsCount | number }}</div>
        <img class="col-1" (click)="viewConnectedAssets(2, dt.id, dt.name)" title="View" src="assets/icons/angles-right.svg">
      </div>
      <div class="row conn-assets">
        <div class="col-4">{{'TRANSFORMERS.EDIT.CONNECTED_POLES_NUM' | translate}}</div>
        <div class="col-7">{{ dt.polesCount | number }}</div>
        <img class="col-1" (click)="viewConnectedAssets(3, dt.id, dt.name)" title="View" src="assets/icons/angles-right.svg">
      </div>
    </div>

    <div class="image-container main-col">
      <div class="card container">
        <div>{{'COMMON.PICTURES.TAG_MOUNTED' | translate}}</div>
        <div *ngIf="getMountedTagImage()">
          <img src="{{ getMountedTagImage() }}" (click)="showImage(ImageType.MountedTag)" data-bs-toggle="modal"
            data-bs-target="#modalImage" alt="Item image" />
        </div>
        <div *ngIf="!getMountedTagImage()">
          <input [disabled]="!permissionsService.isTransformersCreateEditAllowed" hidden id="fileFw1" type="file"
            (change)="fileBrowserHandler($event, ImageType.MountedTag)" />
          <label for="fileFw1" [ngClass]="{'disabled-label': !permissionsService.isTransformersCreateEditAllowed}">
            <img src="../../../../assets/missing_image.png" alt="Missing image"/>
          </label>
        </div>
      </div>
      <div class="card container">
        <div>{{'COMMON.PICTURES.FULL_DT_LOCATION' | translate}}</div>
        <div *ngIf="getLocationImage()">
          <img src="{{ getLocationImage() }}" (click)="showImage(ImageType.Location)" data-bs-toggle="modal"
            data-bs-target="#modalImage" alt="Item image" />
        </div>
        <div *ngIf="!getLocationImage()">
          <input [disabled]="!permissionsService.isTransformersCreateEditAllowed" hidden id="fileFw2" type="file"
            (change)="fileBrowserHandler($event, ImageType.Location)" />
          <label for="fileFw2" [ngClass]="{'disabled-label': !permissionsService.isTransformersCreateEditAllowed}">
            <img src="../../../../assets/missing_image.png" alt="Missing image"/>
          </label>
        </div>
      </div>
      <div class="card container">
        <div>{{'COMMON.PICTURES.INPUT_CABLE_DT' | translate}}</div>

        <div *ngIf="getInputCableImage()">
          <img src="{{ getInputCableImage() }}" (click)="showImage(ImageType.InputCable)" data-bs-toggle="modal"
            data-bs-target="#modalImage" alt="Item image"/>
        </div>
        <div *ngIf="!getInputCableImage()">
          <input [disabled]="!permissionsService.isTransformersCreateEditAllowed" hidden id="fileFw3" type="file"
            (change)="fileBrowserHandler($event, ImageType.InputCable)" />
          <label for="fileFw3" [ngClass]="{'disabled-label': !permissionsService.isTransformersCreateEditAllowed}">
            <img src="../../../../assets/missing_image.png" alt="Missing image"/>
          </label>
        </div>
      </div>

      <div class="card container">
        <div>{{'COMMON.PICTURES.OUTPUT_CABLE_DT' | translate}}</div>
        <div *ngIf="getOutputCableImage()">
          <img src="{{ getOutputCableImage() }}" (click)="showImage(ImageType.OutputCable)" data-bs-toggle="modal"
            data-bs-target="#modalImage" alt="Item image"/>
        </div>
        <div *ngIf="!getOutputCableImage()">
          <input [disabled]="!permissionsService.isTransformersCreateEditAllowed" hidden id="fileMounted" type="file"
            (change)="fileBrowserHandler($event, ImageType.OutputCable)" />
          <label for="fileMounted" [ngClass]="{'disabled-label': !permissionsService.isTransformersCreateEditAllowed}">
            <img src="../../../../assets/missing_image.png" alt="Missing image"/>
          </label>
        </div>
      </div>

      <div class="card container">
        <div>{{'COMMON.PICTURES.NAMEPLATE' | translate}}</div>
        <div *ngIf="getNameplateImage()">
          <img src="{{ getNameplateImage() }}" (click)="showImage(ImageType.NameplatePicture)" data-bs-toggle="modal"
            data-bs-target="#modalImage" alt="Item image"/>
        </div>
        <div *ngIf="!getNameplateImage()">
          <input [disabled]="!permissionsService.isTransformersCreateEditAllowed" hidden id="fileMounted" type="file"
            (change)="fileBrowserHandler($event, ImageType.NameplatePicture)" />
          <label for="fileMounted" [ngClass]="{'disabled-label': !permissionsService.isTransformersCreateEditAllowed}">
            <img src="../../../../assets/missing_image.png" alt="Missing image"/>
          </label>
        </div>
      </div>


      <div class="card container">
        <div>{{'COMMON.PICTURES.DT_METER_NUM' | translate}}</div>
        <div *ngIf="getDtMeterNumberImage()">
          <img src="{{ getDtMeterNumberImage() }}" (click)="showImage(ImageType.MeterNumber)" data-bs-toggle="modal"
            data-bs-target="#modalImage" alt="Item image"/>
        </div>
        <div *ngIf="!getDtMeterNumberImage()">
          <input [disabled]="!permissionsService.isTransformersCreateEditAllowed" hidden id="fileMounted" type="file"
            (change)="fileBrowserHandler($event, ImageType.MeterNumber)" />
          <label for="fileMounted" [ngClass]="{'disabled-label': !permissionsService.isTransformersCreateEditAllowed}">
            <img src="../../../../assets/missing_image.png" alt="Missing image"/>
          </label>
        </div>


      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="assignToFieldAgent" tabindex="-1" aria-hidden="true"
  (hidden.bs.modal)="onModalAssignFade()">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 *ngIf="assetAssignType == AssetAssignType.Assigned" class="modal-title">{{'COMMON.ASSIGN_AGENT' | translate}}</h5>
        <h5 *ngIf="assetAssignType == AssetAssignType.Retagged" class="modal-title">{{'COMMON.RETAG_TASK' | translate}}</h5>
        <button type="button" #closeModal class="btn-cancel-modal" data-bs-dismiss="modal" aria-label="Close">
          {{'COMMON.CANCEL' | translate}}
        </button>
      </div>
      <div class="modal-body">
        <div class="form-floating form-item">
          <input #userFilterInput autocomplete="off" type="text" name="filterText" [(ngModel)]="filterText"
            class="form-control filter-field" [ngClass]="{ 'valid-input': tempAssignedUserId != 0 }" id="floatingInput"
            (ngModelChange)="filterMobileUsers()" (click)="openDropdown()" placeholder=" " />
          <label for="floatingInput">{{'COMMON.FIELD_AGENT' | translate}}</label>

          <div class="filtered-agent-list" *ngIf="isDropdownOpened" #dropdownContainer>
            <div click-stop-propagation class="agent-name" (click)="selectFieldAgent(u.id)"
              *ngFor="let u of mobileUsers">{{u.fullName}}</div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="assignTask()">
          <span *ngIf="assetAssignType == AssetAssignType.Assigned">{{'COMMON.ASSIGN_TASK' | translate}}</span>
          <span *ngIf="assetAssignType == AssetAssignType.Retagged">{{'COMMON.RETAG_TASK' | translate}}</span>
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="modalImage" tabindex="-1" aria-hidden="true" (hidden.bs.modal)="onModalFade()"
  (shown.bs.modal)="onModalOpen()">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content modal-image">
      <div class="modal-body modal-body-image">
        <div class="image-container" [style.transform]="'rotate(' + currentRotation + 'deg)'">
          <lib-ngx-image-zoom [zoomMode]="'hover-freeze'" [fullImage]="selectedImage" [thumbImage]="selectedImage"
            [magnification]="3">
          </lib-ngx-image-zoom>
        </div>
        <div class="rotate-div">
          <button class="btn btn-primary left-button" (click)="rotateLeft()">&lt;</button>
          <button class="btn btn-primary right-button" (click)="rotateRight()">&gt;</button>
        </div>
      </div>
    </div>
  </div>
</div>



<div class="modal modalMap" id="showMap" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" #closeAddModal class="close" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-building-map [setMapVisibility]="isMapVisible" [longitude]="dt.longitude"
          [latitude]="dt.latitude"></app-building-map>
      </div>
    </div>
  </div>
</div>

<app-history (update)="updateHistory($event)"></app-history>

<div class="modal" id="updateStatus" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{'COMMON.DATA_VALID_REVIEW' | translate}}</h5>
        <button type="button" #closeModal class="btn-cancel-modal" data-bs-dismiss="modal" aria-label="Close">
          {{'COMMON.CANCEL' | translate}}
        </button>
      </div>
      <div class="modal-body">
        <app-data-quality assetType="Dt" [assetId]="id" [areaId]="dt.area.id"></app-data-quality>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="auditReview" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{'COMMON.FIELD_AUDIT' | translate}}</h5>
        <button type="button" #closeAudit class="btn-cancel-modal" data-bs-dismiss="modal" aria-label="Close">
          {{'COMMON.CANCEL' | translate}}
        </button>
      </div>
      <app-audit-review (closeModal)="cancelAudit()" assetType="Dt" [assetId]="id"
        [areaId]="dt.area.id"></app-audit-review>
    </div>
  </div>
</div>

<div class="modal" id="rejectionReason" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{'COMMON.REASON_FOR_REJECT' | translate}}</h5>
      </div>
      <div class="p-4">
        {{ modalRejectionReason }}
      </div>
      <button type="button" #closeRejectionReason class="btn btn-primary w-50 mx-auto"  data-bs-dismiss="modal" aria-label="Close">
        {{'COMMON.CLOSE' | translate}}
      </button>
    </div>
  </div>
</div>
