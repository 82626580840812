<div class="edit-customer-root">
  <div class="edit-customers-header">
    <div class="user-header-info">
      <div class="user-info">
        <div class="name">{{'COMMON.MBC_DETAILS.BUILDING_SLRN' | translate}}</div>
        <div class="slrn">
          {{
          selectedBuilding.connectedCustomers.length > 0
          ? selectedBuilding.connectedCustomers[0].accountNumber
          : ""
          }}
        </div>
        <div class="tag-customer">
          {{
          selectedBuilding.connectedCustomers.length > 0
          ? selectedBuilding.connectedCustomers[0].useOfPremises
          : ""
          }}
        </div>
        <div class="col-2 dt-status approved-building"
          *ngIf="selectedBuilding.dataQualityStatus!.toString() =='Approved'"> {{'COMMON.ASSET_STATUS.COMPLETE' | translate}} </div>

        <div class="col-2 dt-status rejected-building"
          *ngIf="selectedBuilding.dataQualityStatus!.toString() =='Rejected'"> {{'COMMON.ASSET_STATUS.REJECTED' | translate}} </div>

        <div class="col-2 dt-status" *ngIf="selectedBuilding.status && !selectedBuilding.dataQualityStatus"
            [ngClass]="{'pending-building' : selectedBuilding.status == 'Pending',
                        'assigned-building' : selectedBuilding.status == 'Assigned' || selectedBuilding.status == 'Retagged'}"> 
          {{'COMMON.ASSET_STATUS.' + selectedBuilding.status | uppercase | translate}} 
        </div>
        <div class="col-2 dt-status pending-building"
          *ngIf="!selectedBuilding.status && !selectedBuilding.dataQualityStatus">--------</div>
      </div>
      <div class="user-address">
        <div></div>
        <div>
          {{ selectedBuilding.slrn ? selectedBuilding.slrn : ("COMMON.NO_SLRN" | translate) }}
        </div>
        <div>
          {{
          selectedBuilding.address ? selectedBuilding.address : ("BUILDINGS.EDIT.NO_ADDRESS" | translate)
          }}
        </div>
      </div>
    </div>
    <div class="action-buttons">
      <button class="btn btn-header" data-bs-toggle="modal"
        (click)="getMobileUsers(); setModalType(AssetAssignType.Retagged);" data-bs-target="#assignToFieldAgent"
        *ngIf="permissionsService.isBuildingRetagAllowed">
        {{'COMMON.RETAG' | translate}}
      </button>
      <div class="recall-div" *ngIf="
          permissionsService.isBuildingRecallAssignedAllowed ||
          permissionsService.isBuildingRecallValidatedAllowed
        ">
        <button class="btn btn-header recall-button" style="color: black" (click)="showRecallMenu = !showRecallMenu">
          <span class="recall-span">{{'COMMON.RECALL' | translate}}</span>
        </button>
        <div *ngIf="showRecallMenu" class="recall-options">
          <div class="recall-type" [ngClass]="[
              !recallAssignedAllowed
                ? 'recall-type-disabled'
                : 'recall-type-enabled'
            ]" (click)="
              recallAssignedAllowed
                ? recallAssigned()
                : null
            ">
            <span>{{'COMMON.ASSIGNED_TASK' | translate}}</span>
          </div>
          <div class="recall-type" [ngClass]="[
              !recallValidatedAllowed
                ? 'recall-type-disabled'
                : 'recall-type-enabled'
            ]" (click)="
              recallValidatedAllowed
                ? recallValidation()
                : null
            ">
            <span>{{'COMMON.VALIDATED_TASK' | translate}}</span>
          </div>
        </div>
      </div>

      <button class="btn btn-header" data-bs-toggle="modal" data-bs-target="#updateStatus"
        *ngIf="permissionsService.isBuildingApproveRejectAllowed" [disabled]="selectedBuilding.dataQualityStatus">
        {{'COMMON.UPDATE_STATUS' | translate}}
      </button>
      <button class="btn btn-header" data-bs-toggle="modal" data-bs-target="#auditReview"
        [disabled]="disableAuditReviewButton()" *ngIf="permissionsService.isBuildingAuditReviewAllowed">
        {{'COMMON.FIELD_AUDIT' | translate}}
      </button>
      <button class="btn btn-header" data-bs-toggle="modal" data-bs-target="#assignToFieldAgent"
        (click)="getMobileUsers(); setModalType(AssetAssignType.Assigned);"
        *ngIf="permissionsService.isBuildingAssignAllowed">
        {{'COMMON.ASSIGN_AGENT' | translate}}
      </button>
      <button class="btn btn-header" *ngIf="!editEnabled && permissionsService.isBuildingCreateEditAllowed"
        (click)="edit()">
        {{'COMMON.EDIT' | translate}}
      </button>
      <button class="btn btn-primary" *ngIf="editEnabled" (click)="save()">
        {{'COMMON.SAVE' | translate}}
      </button>
    </div>
  </div>
  <div class="edit-customers-body">
    <div class="main-col">
      <div class="card container">
        <div class="row">
          <div class="col-4 header-title">{{'BUILDINGS.INFO' | translate}}</div>
          <div class="col-8">
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col-4">{{'COMMON.MBC_DETAILS.PHYSICAL_ADDRESS' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-8">
            {{ selectedBuilding.address | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-7" [(ngModel)]="selectedBuilding.address" />
        </div>
        <div class="row">
          <div class="col-4">{{'COMMON.MBC_DETAILS.CLOSEST_LANDMARK' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-8">
            {{ selectedBuilding.closestLandmark | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-8" [(ngModel)]="selectedBuilding.closestLandmark" />
        </div>
        <div class="row">
          <div class="col-4">{{'COMMON.TABLE_HEADERS.BUILDING_OWNER' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-8">
            {{ selectedBuilding.buildingOwner | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-8" [(ngModel)]="selectedBuilding.buildingOwner" />
        </div>
        <div class="row">
          <div class="col-4">{{'BUILDINGS.EDIT.COMMENT' | translate}}</div>
          <div class="col-8">{{ selectedBuilding.comment | hasValue }}</div>
        </div>
        <div class="row">
          <div class="col-4">{{'BUILDINGS.EDIT.VISITS_NO' | translate}}</div>
          <div class="col-8">{{ selectedBuilding.numberOfRevisits | hasValue }}</div>
        </div>
        <div class="row">
          <div class="col-4">{{'COMMON.MBC_DETAILS.BUILDING_STRUCTURE.NAME' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-8">
            {{ selectedBuilding.buildingStructure | hasValue }}
          </div>
          <div *ngIf="editEnabled" class="col-8">
            <select class="form-control" [(ngModel)]="selectedBuilding.buildingStructure">
              <option *ngFor="let option of structureOptions" [ngValue]="structure[option.key]">
                {{ 'COMMON.MBC_DETAILS.BUILDING_STRUCTURE.' + option.value | uppercase | translate }}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-4">{{'COMMON.MBC_DETAILS.BUILDING_DEVELOPMENT.NAME' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-8">
            {{ selectedBuilding.buildingDevelopmentStage | hasValue }}
          </div>   
          <div *ngIf="editEnabled" class="col-8">
            <select class="form-control" [(ngModel)]="selectedBuilding.buildingDevelopmentStage">
              <option *ngFor="let option of developmentStageOptions" [ngValue]="developmentStage[option.key]">
                {{ option.value }}
              </option>
            </select>
          </div>
        </div>
        <div class="row conn-assets">
          <div class="col-5">{{ 'COMMON.POLE.CONNECTED_LT_POLE' | translate }}</div>
          <div class="col-7 d-flex align-items-center justify-content-end">
            {{ selectedBuilding.poleSlrn | hasValue }}
            <img *ngIf="selectedBuilding?.poleId" class="ml-3" (click)="viewConnectedAssets(1)" title="View" src="assets/icons/angles-right.svg">
          </div>
        </div> 
        <div class="row">
          <div class="col-4">{{'COMMON.MBC_DETAILS.FEEDER_NAME' | translate}}</div>

          <div *ngIf="!editEnabled" class="col-8">
            {{ selectedBuilding.dt?.feeder?.name | hasValue }}
          </div>
          <div *ngIf="editEnabled" class="col-8">
            <select *ngIf="selectedBuilding.dt.feeder.id !== 0" class="form-control"
              [(ngModel)]="selectedBuilding.feederId" (change)="selectFeeder()">
              <option *ngFor="let option of f11Options" [ngValue]="option.id">
                {{ option.name }}
              </option>
            </select>
            <select *ngIf="selectedBuilding.dt.feeder.id !== 0" class="form-control"
              [(ngModel)]="selectedBuilding.feederId" (change)="selectFeeder()">
              <option *ngFor="let option of f33Options" [ngValue]="option.id">
                {{ option.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="col-5">{{'COMMON.MBC_DETAILS.FEEDER_NUMBER' | translate}}</div>
          <div class="col-7">
            {{ selectedBuilding.dt?.feeder?.number | hasValue }}
          </div>
        </div>
        <div class="row">
          <div class="col-4">{{'COMMON.DT_NAME' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-8">
            {{ selectedBuilding.dt?.name | hasValue }}
          </div>
          <div *ngIf="editEnabled" class="col-8">
            <select required class="form-control" [(ngModel)]="selectedBuilding.dtId">
              <option hidden selected>{{'TRANSFORMERS.SELECT' | translate}}</option>
              <option *ngFor="let option of dtOptions" [ngValue]="option.key">
                {{ option.value }}
              </option>
            </select>
          </div>
        </div>
        <div class="row header-title-row">
          <div class="col-4 header-title">{{'BUILDINGS.CONNECTED_ACCS' | translate}}</div>
          <div class="col-8">
            <hr />
          </div>
        </div>
        <div class="row" *ngFor="let customer of selectedBuilding.connectedCustomers">
          <div class="col-3">{{ customer.tenantName | hasValue }}</div>
          <div class="col-3">{{ customer.buildingOwner | hasValue }}</div>
          <div class="col-3">{{ customer.meterNumber | hasValue }}</div>
          <div class="col-2">{{ customer.accountNumber | hasValue }}</div>
          <div *ngIf="customer.dataQualityId == 0" class="col-1 pending" (click)="navigateToCustomer(customer)">
            <svg fill="gray" height="1em" viewBox="0 0 640 512">
              <path
                d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H322.8c-3.1-8.8-3.7-18.4-1.4-27.8l15-60.1c2.8-11.3 8.6-21.5 16.8-29.7l40.3-40.3c-32.1-31-75.7-50.1-123.9-50.1H178.3zm435.5-68.3c-15.6-15.6-40.9-15.6-56.6 0l-29.4 29.4 71 71 29.4-29.4c15.6-15.6 15.6-40.9 0-56.6l-14.4-14.4zM375.9 417c-4.1 4.1-7 9.2-8.4 14.9l-15 60.1c-1.4 5.5 .2 11.2 4.2 15.2s9.7 5.6 15.2 4.2l60.1-15c5.6-1.4 10.8-4.3 14.9-8.4L576.1 358.7l-71-71L375.9 417z" />
            </svg>
          </div>
          <div *ngIf="
              customer.dataQualityId != 0 &&
              customer.dataQualityStatus == 'Approved'
            " class="col-1 approved">
            <svg fill="#00875A" height="1em" viewBox="0 0 640 512">
              <path
                d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM625 177L497 305c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L591 143c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
            </svg>
          </div>
          <div *ngIf="
              customer.dataQualityId != 0 &&
              customer.dataQualityStatus == 'Rejected'
            " class="col-1 rejected">
            <svg fill="#DE350B" height="1em" viewBox="0 0 640 512">
              <path
                d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM471 143c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
            </svg>
          </div>
        </div>
      </div>
      <div *ngIf="selectedBuilding.customerInaccessible" class="card container">
        <div class="row">
          <div class="col-5 header-title">{{'BUILDINGS.EDIT.ACCESSIBILITY_ISSUE' | translate}}</div>
          <div class="col-7">
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col-4">{{'COMMON.REASON' | translate}}</div>
          <div class="col-8">
            {{ selectedBuilding.customerInaccessible.issueKind | hasValue }}
          </div>
        </div>
        <div *ngIf="selectedBuilding.customerInaccessible.issueKind === 'Other'" class="row">
          <div class="col-4">{{'BUILDINGS.EDIT.ISSUE_TYPE' | translate}}</div>
          <div class="col-8">
            {{ selectedBuilding.customerInaccessible.issueType | hasValue }}
          </div>
        </div>
        <div class="row">
          <div class="col-4">{{'BUILDINGS.EDIT.REMARK' | translate}}</div>
          <div class="col-8">
            {{ selectedBuilding.customerInaccessible.description | hasValue }}
          </div>
        </div>
      </div>
      <div *ngIf="selectedBuilding.inaccessibleMeters.length > 0">
        <div class="card container" style="max-height: 500px; overflow-y: scroll">
          <div class="row">
            <div class="col-4 header-title">{{'BUILDINGS.INACCESIBLE_METERS' | translate}}</div>
            <div class="col-8">
              <hr />
            </div>

            <div class="row" *ngFor="let inaccessible of selectedBuilding.inaccessibleMeters">
              <div class="col-3">{{ inaccessible.number }}</div>
              <div class="col-9">
                <div *ngIf="inaccessible.meterImage != undefined">
                  <img src="{{ inaccessible.meterImage.url }} "
                    (click)="showInaccessibleImage(inaccessible.meterImage.url)" data-bs-toggle="modal"
                    data-bs-target="#modalImage" alt="Meter image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <app-history-tracker [histories]="selectedBuilding.buildingHistories"></app-history-tracker>
      <div  *ngIf="isDataQuality && selectedBuilding.dataValidationReview != null && selectedBuilding.dataValidationReview.length > 0" class="history-card container">
        <h4 style="text-align: center">{{'COMMON.DATA_VALID_REVIEW' | translate}}</h4>
        <div class="row" *ngFor="let dq of selectedBuilding.dataValidationReview">
          <div class="col-5">{{ dq.key }}</div>
          <div *ngIf="dq.key != 'Reason for rejection'" class="col-7">
            {{ dq.value| hasValue }}
          </div>
          <div *ngIf="dq.key == 'Reason for rejection'" class="col-7">
            <a data-bs-toggle="modal" data-bs-target="#rejectionReason">{{'COMMON.VIEW' | translate | lowercase}}</a>
          </div>
        </div>
      </div>
    </div>

    <div class="card container main-col">
      <div class="row">
        <div class="col-4 header-title">{{'COMMON.MBC_DETAILS.METER_INFO' | translate}}</div>
        <div class="col-8">
          <hr />
        </div>
      </div>
      <div class="row">
        <div class="col-4">{{'BUILDINGS.EDIT.METER_NO' | translate}}</div>
        <div class="col-8">
          {{
          selectedBuilding.connectedCustomers.length
          ? (selectedBuilding.numberOfMeters | number)
          : "----"
          }}
        </div>
      </div>
      <div class="row">
        <div class="col-4">{{'COMMON.MBC_DETAILS.METER_STATUS' | translate}}</div>
        <div class="col-8">
          {{
          selectedBuilding.connectedCustomers.length
          ? renderMeterStatus()
          ? "Metered"
          : "Unmetered"
          : "----"
          }}
        </div>
      </div>
      <div class="row">
        <div class="col-4">{{'COMMON.MBC_DETAILS.CONNECTED_CUSTOMERS' | translate}}</div>
        <div class="col-8">
          {{
          selectedBuilding.connectedCustomers.length
          ? (selectedBuilding.connectedCustomers.length | number)
          : "----"
          }}
        </div>
      </div>
      <div class="row">
        <div class="col-4">{{'BUILDINGS.INACCESSIBLE_METERS' | translate}}</div>
        <div class="col-8">
          {{
          selectedBuilding.inaccessibleMeters.length
          ? (selectedBuilding.inaccessibleMeters.length | number)
          : "----"
          }}
        </div>
      </div>
      <div class="row header-title-row">
        <div class="col-4 header-title">{{'BUILDINGS.EDIT.OTHER_INFO' | translate}}</div>
        <div class="col-8">
          <hr />
        </div>
      </div>
      <div class="row">
        <div class="col-4">{{'COMMON.BU.SINGLE' | translate}}</div>
        <div class="col-8">{{ selectedBuilding.regionName | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-4">{{'COMMON.UT.SINGLE' | translate}}</div>
        <div class="col-8">{{ selectedBuilding.areaName | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-4">{{'COMMON.USER.TYPE.FIRST_CAPTURED' | translate}}</div>
        <div class="col-8">
          {{ selectedBuilding.firstCapturedUser | hasValue }}
        </div>
      </div>
      <div class="row">
        <div class="col-4">{{'COMMON.USER.TYPE.LAST_CAPTURED' | translate}}</div>
        <div class="col-8">
          {{ selectedBuilding.lastUpdatedUser | hasValue }}
        </div>
      </div>
      <div class="row">
        <div class="col-4">{{'COMMON.CREATION_DATE' | translate}}</div>
        <div class="col-8">
          {{selectedBuilding.creationDate| date:'dd/MM/yyyy HH:mm':'{{ timeZone }}' | hasValue}}
        </div>
      </div>
      <div class="row">
        <div class="col-4">{{'COMMON.MBC_DETAILS.UPDATED_DATE' | translate}}</div>
        <div class="col-8">
          {{selectedBuilding.updatedDate| date:'dd/MM/yyyy HH:mm':'{{ timeZone }}' | hasValue}}

        </div>
      </div>
      <div class="row">
        <div class="col-4">{{'COMMON.USER.TYPE.REASSIGNED' | translate}}</div>
        <div class="col-8">{{ selectedBuilding.reassignedBy | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-4">{{'COMMON.MBC_DETAILS.ASSIGNED' | translate}}</div>
        <div class="col-8">{{ selectedBuilding.assignedUser | hasValue }}</div>
      </div>
      <div class="row" *ngIf="permissionsService.isValidationStatusAllowed">
        <div class="col-4">{{'COMMON.VALIDATION_STATUS' | translate}}</div>

        <div class="col-8" *ngIf="selectedBuilding.dataQualityStatus!.toString() =='Approved'"> {{'COMMON.ASSET_STATUS.COMPLETE' | translate}} </div>
        <div class="col-8" *ngIf="selectedBuilding.dataQualityStatus!.toString() =='Rejected'"> {{'COMMON.ASSET_STATUS.REJECTED' | translate}} </div>
        <div class="col-8" *ngIf="selectedBuilding.status && !selectedBuilding.dataQualityStatus"> 
          {{'COMMON.ASSET_STATUS.' + selectedBuilding.status | uppercase | translate}} 
        </div>
        <div class="col-8" *ngIf="!selectedBuilding.status && !selectedBuilding.dataQualityStatus">--------</div>
      </div>
      <div class="row" *ngIf="permissionsService.isValidationStatusAllowed">
        <div class="col-4">{{'COMMON.USER.TYPE.VALIDATED' | translate}}</div>
        <div class="col-8">{{ selectedBuilding.validatedBy | hasValue }}</div>
      </div>
      <div class="row" *ngIf="permissionsService.isValidationStatusAllowed">
        <div class="col-4">{{'COMMON.DATE_TYPE.VALIDATED' | translate}}</div>
        <div class="col-8">
          {{selectedBuilding.validatedDate| date:'dd/MM/yyyy HH:mm':'{{ timeZone }}' | hasValue}}
        </div>
      </div>
      <div class="row" *ngIf="permissionsService.isAuditStatusAllowed">
        <div class="col-4">{{'COMMON.MBC_DETAILS.AUDITED_STATUS' | translate}}</div>
        <div class="col-8">{{ selectedBuilding.auditedStatus | hasValue }}</div>
      </div>
      <div class="row" *ngIf="permissionsService.isAuditStatusAllowed">
        <div class="col-4">{{'COMMON.USER.TYPE.AUDITED' | translate}}</div>
        <div class="col-8">{{ selectedBuilding.auditedBy | hasValue }}</div>
      </div>
      <div class="row" *ngIf="permissionsService.isAuditStatusAllowed">
        <div class="col-4">{{'COMMON.MBC_DETAILS.AUDITED_DATE' | translate}}</div>
        <div class="col-8">
          {{selectedBuilding.auditedDate| date:'dd/MM/yyyy HH:mm':'{{ timeZone }}' | hasValue}}
        </div>
      </div>

      <ng-container *ngIf="isGhanaPostFieldsVisible">
        <div class="row">
          <div class="col-5">{{'COMMON.GHANA_POST_GPS' | translate}}</div>
          <div class="col-7">{{ selectedBuilding.postCode | hasValue }}</div>
        </div>

        <div class="row" *ngIf="selectedBuilding.postCodeException">
          <div class="col-5">{{'COMMON.GHANA_POST_GPS_EXC' | translate}}</div>
          <div class="col-7">
            {{ selectedBuilding.postCodeException | hasValue }}
          </div>
        </div>
      </ng-container>

      <div class="row">
        <div class="locationText col-5">{{'BUILDINGS.EDIT.GEO_COORDINATES' | translate}}</div>
        <div class="locationText col-6">
          {{ selectedBuilding.latitude }} , {{ selectedBuilding.longitude }}
        </div>
        <div class="location col-1" (click)="isMapVisible = !isMapVisible">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" data-bs-toggle="modal"
            data-bs-target="#showMap">
            <path
              d="M256 0c17.7 0 32 14.3 32 32V66.7C368.4 80.1 431.9 143.6 445.3 224H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H445.3C431.9 368.4 368.4 431.9 288 445.3V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V445.3C143.6 431.9 80.1 368.4 66.7 288H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H66.7C80.1 143.6 143.6 80.1 224 66.7V32c0-17.7 14.3-32 32-32zM128 256c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128s-128 57.3-128 128zm128 80c-44.2 0-80-35.8-80-80s35.8-80 80-80s80 35.8 80 80s-35.8 80-80 80z"
              fill="#026962" />
          </svg>
        </div>
      </div>
    </div>
    <div class="image-container main-col">
      <div class="card container">
        <div>{{'COMMON.PICTURES.FRONT' | translate}}</div>
        <div class="images-list">
          <div *ngIf="getFrontViewImage(0)">
            <img src="{{ getFrontViewImage(0) }}" (click)="showImage(0)" data-bs-toggle="modal"
              data-bs-target="#modalImage" alt="Front view image" />
          </div>
          <div *ngIf="!getFrontViewImage(0)">
            <input hidden id="fileFw1" type="file" (change)="fileBrowserHandler($event, ImageType.Frontview)"
              [disabled]="!permissionsService.isBuildingCreateEditAllowed" />
            <label for="fileFw1" [ngClass]="{
                'disabled-label':
                  !permissionsService.isBuildingCreateEditAllowed
              }">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
          <div *ngIf="getFrontViewImage(1)">
            <img src="{{ getFrontViewImage(1) }}" (click)="showImage(1)" data-bs-toggle="modal"
              data-bs-target="#modalImage" alt="Front view image"/>
          </div>
          <div *ngIf="!getFrontViewImage(1)">
            <input hidden id="fileFw2" type="file" (change)="fileBrowserHandler($event, ImageType.Frontview)"
              [disabled]="!permissionsService.isBuildingCreateEditAllowed" />
            <label for="fileFw2" [ngClass]="{
                'disabled-label':
                  !permissionsService.isBuildingCreateEditAllowed
              }">
              <img src="../../../../assets/missing_image.png" alt="Missing image" />
            </label>
          </div>
          <div *ngIf="getFrontViewImage(2)">
            <img src="{{ getFrontViewImage(2) }}" (click)="showImage(2)" data-bs-toggle="modal"
              data-bs-target="#modalImage" alt="Front view image"/>
          </div>
          <div *ngIf="!getFrontViewImage(2)">
            <input hidden id="fileFw3" type="file" (change)="fileBrowserHandler($event, ImageType.Frontview)"
              [disabled]="!permissionsService.isBuildingCreateEditAllowed" />
            <label for="fileFw3" [ngClass]="{
                'disabled-label':
                  !permissionsService.isBuildingCreateEditAllowed
              }">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
      <div class="card container">
        <div>{{'COMMON.PICTURES.BUILDING_TAG' | translate}}</div>
        <div>
          <div *ngIf="getBuildingMountedImage()">
            <img src="{{ getBuildingMountedImage() }}" (click)="showImage(3)" data-bs-toggle="modal"
              data-bs-target="#modalImage" alt="Mounted image"/>
          </div>
          <div *ngIf="!getBuildingMountedImage()">
            <input hidden id="fileMounted" type="file" (change)="fileBrowserHandler($event, ImageType.MountedTag)"
              [disabled]="!permissionsService.isBuildingCreateEditAllowed" />
            <label for="fileMounted" [ngClass]="{
                'disabled-label':
                  !permissionsService.isBuildingCreateEditAllowed
              }">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="assignToFieldAgent" tabindex="-1"  aria-hidden="true"
  (hidden.bs.modal)="onModalAssignFade()">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 *ngIf="assetAssignType == AssetAssignType.Assigned" class="modal-title">{{'COMMON.ASSIGN_AGENT' | translate}}</h5>
        <h5 *ngIf="assetAssignType == AssetAssignType.Retagged" class="modal-title">{{'COMMON.RETAG_TASK' | translate}}</h5>
        <button type="button" #closeModal class="btn-cancel-modal" data-bs-dismiss="modal" aria-label="Close">
          {{'COMMON.CANCEL' | translate}}
        </button>
      </div>
      <div class="modal-body">
        <div class="form-floating form-item">
          <input #userFilterInput autocomplete="off" type="text" name="filterText" [(ngModel)]="filterText"
            class="form-control filter-field" [ngClass]="{ 'valid-input': tempAssignedUserId != 0 }" id="floatingInput"
            (ngModelChange)="filterMobileUsers()" (click)="openDropdown()" placeholder=" " />
          <label for="floatingInput">{{'COMMON.FIELD_AGENT' | translate}}</label>

          <div class="filtered-agent-list" *ngIf="isDropdownOpened" #dropdownContainer>
            <div click-stop-propagation class="agent-name" (click)="selectFieldAgent(u.id)"
              *ngFor="let u of mobileUsers">
              {{ u.fullName }}
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="assignTask()">
          <span *ngIf="assetAssignType == AssetAssignType.Assigned">{{'COMMON.ASSIGN_TASK' | translate}}</span>
          <span *ngIf="assetAssignType == AssetAssignType.Retagged">{{'COMMON.RETAG_TASK' | translate}}</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modalImage" tabindex="-1" aria-hidden="true" (hidden.bs.modal)="onModalFade()"
  (shown.bs.modal)="onModalOpen()">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content modal-image">
      <div class="modal-body modal-body-image">
        <div class="image-container" [style.transform]="'rotate(' + currentRotation + 'deg)'">
          <lib-ngx-image-zoom [zoomMode]="'hover-freeze'" [fullImage]="selectedImage" [thumbImage]="selectedImage"
            [magnification]="3">
          </lib-ngx-image-zoom>
        </div>
        <div class="rotate-div">
          <button class="btn btn-primary left-button" (click)="rotateLeft()">
            &lt;
          </button>
          <button class="btn btn-primary right-button" (click)="rotateRight()">
            &gt;
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal modalMap" id="showMap" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" #closeAddModal class="close" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-building-map [setMapVisibility]="isMapVisible" [longitude]="selectedBuilding.longitude"
          [latitude]="selectedBuilding.latitude"></app-building-map>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="updateStatus" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{'COMMON.DATA_VALID_REVIEW' | translate}}</h5>
        <button type="button" #closeDQModal class="btn-cancel-modal" data-bs-dismiss="modal" aria-label="Close">
          {{'COMMON.CANCEL' | translate}}
        </button>
      </div>
      <div class="modal-body">
        <app-data-quality assetType="Building" (closeRedirectModal)="closeRedirectModal()"
          [updateStatusDisabled]="!updateStatusDisabled" [assetId]="selectedBuilding.id"
          [areaId]="selectedBuilding.areaId"></app-data-quality>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="auditReview" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{'COMMON.FIELD_AUDIT' | translate}}</h5>
        <button type="button" #closeAudit class="btn-cancel-modal" data-bs-dismiss="modal" aria-label="Close">
          {{'COMMON.CANCEL' | translate}}
        </button>
      </div>
      <app-audit-review (closeModal)="cancel()" assetType="Building" [assetId]="selectedBuilding.id"
        [areaId]="selectedBuilding.areaId" [fieldAuditDisabled]="fieldAuditDisabled"></app-audit-review>
    </div>
  </div>
</div>
<div class="modal" id="rejectionReason" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{'COMMON.REASON_FOR_REJECT' | translate}}</h5>
      </div>
      <div class="p-4">
        {{ modalRejectionReason }}
      </div>
      <button type="button" #closeRejectionReason class="btn btn-primary w-50 mx-auto"  data-bs-dismiss="modal" aria-label="Close">
        {{'COMMON.CLOSE' | translate}}
      </button>
    </div>
  </div>
</div>